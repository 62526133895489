@import "../assets/styles/colors.scss";

.game_wrapper {
  background-color: $primary-color;
  padding: 5% 20%;
  min-height: 100vh;
  color: $light-color;

  /* -------------------------------------------------------------------------- */
  /*                                  PLATFORM                                  */
  /* -------------------------------------------------------------------------- */

  .platform {
    .score_board {
      color: $light-color;
      font-size: 28px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;

      span {
        margin: 30px;
        display: block;
        border: 2px solid $light-color;
        padding: 20px;
        border-radius: 10px;
      }
    }

    /* -------------------------------------------------------------------------- */
    /*                               CURRENT CHOICE                               */
    /* -------------------------------------------------------------------------- */

    .current_choice_wrapper {
      color: $light-color;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 50px;
      text-align: center;

      .hooman_choice {
        img {
          transform: scaleX(-1);
        }
      }

      h1 {
        width: 300px;
        height: 300px;
        font-size: 22px;

        img {
          width: 100%;
          margin-top: 20px;
          object-fit: contain;
        }
      }

      .info {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    /* -------------------------------------------------------------------------- */
    /*                                   CHOICES                                  */
    /* -------------------------------------------------------------------------- */

    .choices {
      margin: 30px 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;

      button {
        background: transparent;
        border: 2px solid $light-color;
        color: $light-color;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        width: 100%;
        margin: 5px;
        opacity: 0.8;
        transition: 0.1s linear;

        span {
          display: block;
        }

        img {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }

        &:hover {
          opacity: 1;
          transform: scale((1.05));
          background-color: $light-color;
          color: $primary-color;
        }
      }
    }

    .game_status {
      text-align: center;
      margin-top: 50px;
      color: $light-color;
      font-size: 22px;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                 MEDIA QUERY                                */
/* -------------------------------------------------------------------------- */

@media only screen and (max-width: 600px) {
  .game_wrapper {
    padding: 50px 20px;
    .platform {
      .score_board {
        font-size: 20px;

        span {
          margin: 25px;
          padding: 10px;
        }
      }

      .current_choice_wrapper {
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 30px;

        .hooman_choice {
          img {
            transform: scaleX(-1);
          }
        }

        h1 {
          width: 200px;
          height: 200px;
          font-size: 18px;
          margin: 10px;

          img {
            margin-top: 20px;

            object-fit: contain;
          }
        }

        .info {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .choices {
        font-size: 14px;

        button {
          padding: 5px;

          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}
