@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
@import "./assets/styles/colors.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Press Start 2P", cursive;
  font-size: 100%;
}
